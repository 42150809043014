
export const FBMMixinsDeliveryData = {
  methods: {


    /**
     * Personal Data Update
     */
    setPopupInformationData() {
      this.changeDataPopup = [
        {
          field: 'name',
          name: 'fbm_ContactName',
          type: 'text',
          value: this.PI.information.personalName,
          valid: this.PI.validation.personalName,
          validTxt: this.PI.validationTranslate.personalName,
        },
        {
          field: 'phone',
          name: 'fbm_PhoneNumber',
          type: 'text',
          value: this.PI.information.personalPhone,
          valid: this.PI.validation.personalPhone,
          validTxt: this.PI.validationTranslate.personalPhone,
        },
        {
          field: 'company',
          name: 'fbm_CompanyName',
          type: 'text',
          value: this.PI.information.personalCompany,
          valid: this.PI.validation.personalCompany,
          validTxt: this.PI.validationTranslate.personalCompany,
        },
        {
          field: 'email',
          name: 'fbm_Email',
          type: 'text',
          value: this.PI.information.personalEmail,
          valid: this.PI.validation.personalEmail,
          validTxt: this.PI.validationTranslate.personalEmail,
        },
      ]
    },

    personalUpdate(data) {
      this.PI.setPersonalName(this._.find(data, {field: 'name'}).value)
      this.PI.setPersonalPhone(this._.find(data, {field: 'phone'}).value)
      this.PI.setPersonalCompany(this._.find(data, {field: 'company'}).value)
      this.PI.setPersonalEmail(this._.find(data, {field: 'email'}).value)

      let validate = this.PI.personalInfoPartsValidate({
        personalName: true,
        // personalPhone: true,
        emailOptional: true,
      })

      if(!validate){
        this.setPopupInformationData()
        return
      }

      this.isModalChangeAddressPopup = false
      this.personal.setPersonalName(this._.find(data, {field: 'name'}).value)
      this.personal.setPersonalPhone(this._.find(data, {field: 'phone'}).value)
      this.personal.setPersonalCompany(this._.find(data, {field: 'company'}).value)
      this.personal.setPersonalEmail(this._.find(data, {field: 'email'}).value)
    },



    /**
     * Delivery Data Update
     */
    setPopupDeliveryData() {
      this.changeDataPopup = [
        {
          field: 'address',
          name: 'fbm_Address',
          type: 'text',
          value: this.Delivery.delivery.address,
          valid: this.Delivery.validation.address,
          validTxt: this.Delivery.validationTranslate.address,
          maxLength: 35,
          autocomplete: 'address-line1',
        },
        {
          field: 'address2',
          name: 'common_addressSecond',
          type: 'text',
          value: this.Delivery.delivery.address2,
          valid: this.Delivery.validation.address2,
          validTxt: this.Delivery.validationTranslate.address2,
          maxLength: 35,
          autocomplete: 'address-line2',
        },
        {
          field: 'city',
          name: 'fbm_City',
          type: 'text',
          value: this.Delivery.delivery.city,
          valid: this.Delivery.validation.city,
          validTxt: this.Delivery.validationTranslate.city,
          autocomplete: 'address-level2',
        },
        {
          field: 'country',
          name: 'fbm_Country',
          type: 'select',
          value: this.Delivery.delivery.country,
          valid: this.Delivery.validation.country,
          validTxt: this.Delivery.validationTranslate.country,
        },
        {
          field: 'zip',
          name: 'fbm_ZipCode',
          type: 'text',
          value: this.Delivery.delivery.zip,
          valid: this.Delivery.validation.zip,
          validTxt: this.Delivery.validationTranslate.zip,
          autocomplete: 'postal-code',
        },
        {
          field: 'region',
          name: 'fbm_Region',
          type: 'text',
          value: this.Delivery.delivery.region,
          valid: this.Delivery.validation.region,
          validTxt: this.Delivery.validationTranslate.region,
          autocomplete: 'address-level1',
        },
      ]
    },

    deliveryUpdate(data) {
      this.Delivery.setAddress(this._.find(data, {field: 'address'}).value)
      this.Delivery.setAddress2(this._.find(data, {field: 'address2'}).value)
      this.Delivery.setCity(this._.find(data, {field: 'city'}).value)
      this.Delivery.setRegion(this._.find(data, {field: 'region'}).value)
      this.Delivery.setCountry(this._.find(data, {field: 'country'}).value)
      this.Delivery.setZip(this._.find(data, {field: 'zip'}).value)

      let validate = this.Delivery.deliveryInfoValidate()

      if(!validate){
        this.setPopupDeliveryData()
        return
      }

      // let prepare = this.Delivery.deliveryPrepare()
      // let prepareData = {
      //   recipient_address: prepare.address,
      //   recipient_city: prepare.city,
      //   recipient_region: prepare.region,
      //   recipient_country_id: prepare.country?.id,
      //   recipient_zip_code: prepare.zip,
      // }

      this.isModalChangeAddressPopup = false
      this.deliveryFBM.setAddress(this._.find(data, {field: 'address'}).value)
      this.deliveryFBM.setAddress2(this._.find(data, {field: 'address2'}).value)
      this.deliveryFBM.setCity(this._.find(data, {field: 'city'}).value)
      this.deliveryFBM.setRegion(this._.find(data, {field: 'region'}).value)
      this.deliveryFBM.setCountry(this._.find(data, {field: 'country'}).value)
      this.deliveryFBM.setZip(this._.find(data, {field: 'zip'}).value)

      this.$emit('reload')

      // this.$store.dispatch('updateDeliveryAddressOrdersFBM', {
      //   id: this.FBM.data.Order.getId(),
      //   data: prepareData
      // }).then(response => {
      //   let status = this.checkUpdateResponse(response)
      //   if(status){
      //     this.isModalChangeAddressPopup = false
      //     this.$emit('reload')
      //     this.deliveryFBM.setAddress(this._.find(data, {field: 'address'}).value)
      //     this.deliveryFBM.setCity(this._.find(data, {field: 'city'}).value)
      //     this.deliveryFBM.setRegion(this._.find(data, {field: 'region'}).value)
      //     this.deliveryFBM.setCountry(this._.find(data, {field: 'country'}).value)
      //     this.deliveryFBM.setZip(this._.find(data, {field: 'zip'}).value)
      //   }
      // })
    },

    /**
     * Delivery Data Update
     */
    setPopupGiftData() {
      this.changeDataPopup = [
        {
          field: 'comment',
          name: 'fbm_GiftMessage',
          type: 'textarea',
          value: this.Gift.getGiftMassage(),
          valid: this.Gift.validation.giftMassage,
          validTxt: this.Gift.validationTranslate.giftMassage,
        },
        {
          field: 'packaging',
          name: '',
          type: 'radio',
          value: this.Gift.getGiftPaper(),
          valid: false,
          validTxt: '',
        },
      ]
    },

    giftUpdate(data) {
      this.Gift.setGiftMassage(this._.find(data, {field: 'comment'}).value)
      this.Gift.setGiftPaper(this._.find(data, {field: 'packaging'}).value)

      let validate = this.Gift.giftMassageValidate(this)

      if(!validate){
        this.setPopupGiftData()
        return
      }

      this.FBM.setGiftMassage(this._.find(data, {field: 'comment'}).value)
      this.FBM.setGiftPaper(this._.find(data, {field: 'packaging'}).value)
      this.isModalChangeAddressPopup = false

      // this.$store.dispatch('updateGiftMassageOrdersFBM', {
      //   id: this.FBM.data.Order.getId(),
      //   data: prepareData
      // }).then(response => {
      //   let status = this.checkUpdateResponse(response)
      //   if(status){
      //     this.isModalChangeAddressPopup = false
      //     // this.deliveryFBM.setAddress(this._.find(data, {field: 'address'}).value)
      //   }
      // })
    },



    /**
     * Delivery Data Update
     */
    setPopupCommentData() {
      this.changeDataPopup = [
        {
          field: 'comment',
          name: 'fbm_CommentOptional',
          type: 'textarea',
          value: this.Comment.getComment(),
          valid: this.Comment.validation.comment,
          validTxt: this.Comment.validationTranslate.comment,
        },
      ]
    },

    commentUpdate(data) {
      this.Comment.setComment(this._.find(data, {field: 'comment'}).value)

      // let validate = this.Comment.commentValidate()
      // console.log(validate);
      // if(!validate){
        this.setPopupCommentData()
        // return
      // }

      this.isModalChangeAddressPopup = false
      this.FBM.setComment(this._.find(data, {field: 'comment'}).value)
    },




    /**
     * API Data Update
     */
    setPopupAPIData() {
      this.changeDataPopup = [
        {
          field: 'orderPrice',
          name: 'fbm_OrderPrice',
          type: 'select',
          value: this.FBM.data.orderPrice,
          valid: false,
          validTxt: false,
        },
        {
          field: 'shippingCost',
          name: 'fbm_shippingCost',
          type: 'text',
          value: this.FBM.data.shippingCost,
          valid: false,
          validTxt: false,
        },
        {
          field: 'skladUsaFee',
          name: 'fbm_SkladUsaFee',
          type: 'text',
          value: this.FBM.data.skladUsaFee,
          valid: false,
          validTxt: false,
        },
      ]
    },

    APIUpdate(data) {
      this.FBM.setOrderPrice(this._.find(data, {field: 'orderPrice'}).value)
      this.FBM.setShippingCost(this._.find(data, {field: 'shippingCost'}).value)
      this.FBM.setSkladUsaFee(this._.find(data, {field: 'skladUsaFee'}).value)
      this.isModalChangeAddressPopup = false
    },


    /**
     * Delivery Data Update
     */
    setPopupConsolidationData() {
      this.changeDataPopup = [
        {
          field: 'consolidationOrderUnionId',
          name: 'fbm_consolidation',
          type: 'select',
          value: this.FBM.data.consolidationOrderUnionId,
          valid: false,
          validTxt: false,
        },
        {
          field: 'consolidationUnionId',
          name: 'fbm_consolidationUnion',
          type: 'select',
          value: this.FBM.data.consolidationUnion,
          valid: false,
          validTxt: false,
        },
      ]
    },

    consolidationUpdate(data) {
      this.FBM.setConsolidationOrderUnionId(this._.find(data, {field: 'consolidationOrderUnionId'}).value)

      this.FBM.setConsolidationUnion(this._.find(data, {field: 'consolidationUnionId'}).value)

      this.isModalChangeAddressPopup = false
    },


    checkUpdateResponse(response) {
      if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

      switch (this.getResponseStatus(response)) {
        /**
         * Success
         */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
          this.openNotify('success', 'common_notificationRecordChanged')

          return true
        }
        /**
         * Validation Error
         */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
          break
        }
        /**
         * Undefined Error
         */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      }

      return false
    },
  }
}
